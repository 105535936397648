import React, { Component } from "react";
import _ from "lodash";
import { Link } from "gatsby";

class Topics extends Component {
  render() {
    const { topics } = this.props;
    
    return (
      <div className="post-card-topics">
        {topics &&
          topics.map(topic => (
            <span key={topic}>
            <Link className="post-card-topic-link"
            key={topic}
            // style={{ textDecoration: "none" }}
            to={`/topics/${_.kebabCase(topic)}`}
            >
            #{topic}
            </Link>
            {" "}</span>
          ))}
      </div>
    );
  }
}

export default Topics;