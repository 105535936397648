import React from "react";
import { Link } from "gatsby"
import Topics from "./topic"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

export default props => {
  const externalUrlTitle = props.node.frontmatter.external ? new URL(props.node.frontmatter.external).hostname : null;

  const title = (<h2 className="post-card-title">{props.node.frontmatter.title || props.node.fields.slug}</h2>)

  return (
    <article
      className={`post-card with-image ${props.count % 3 === 0 && `post-card-large`} ${!props.node.frontmatter.thumbnail && `image-number`}`}
      style={
        props.node.frontmatter.thumbnail && {
          backgroundImage: `url(${
            props.node.frontmatter.thumbnail.childImageSharp.fluid.src
          })`,
        }
      }
    >
      <div className="post-card-content">
          <div>
            <Topics topics={props.node.frontmatter.topics} />
          </div>

          <div>
            {!externalUrlTitle && <Link to={`/blog${props.node.fields.slug}`} className="post-card-link">{title}</Link>}
            {externalUrlTitle && <a href={props.node.frontmatter.external} target="_blank" className="post-card-link">{title}</a>}
          </div>

          <div className="post-card-date">
            {props.node.frontmatter.date}
          </div>
          
          <div className="post-card-body">
            {props.node.frontmatter.description || props.node.excerpt}
          </div>

          <div>
            {!externalUrlTitle && <Link to={`/blog${props.node.fields.slug}`} className="post-card-link post-card-readmore">Read more</Link>}
            {externalUrlTitle && <a href={props.node.frontmatter.external} target="_blank" className="post-card-link post-card-readmore">Read more <FontAwesomeIcon icon={faExternalLinkAlt} size="1x" /></a>}
            
            <p style={{ opacity: 0.5 }}>{externalUrlTitle && `Published via ${externalUrlTitle}`}</p>
          </div>
      </div>
    </article>
  )
}